import React from 'react'
import { css, cx } from '../../../styled-system/css'
import { packages } from '@dup/products'
import { navigate } from 'gatsby'
import { getPath } from '../../utils/getPath'
import ResponsiveImage from './ResponsiveImage'
import { useIsMobile } from '../../utils/useIsMobile'

export const Albums = () => {
  function handleClickNav(dir: string) {
    if (dir) {
      const goTo = '/photographie/' + dir
      navigate(goTo)
    }
  }

  const getPackage = (id: string) => {
    const pkg = packages.find((_package) => _package.id === id)

    const imageName =
      typeof window !== 'undefined' && window.innerWidth > 768
        ? `losange.webp`
        : `${pkg?.images.at(0)?.name}`

    if (!pkg) {
      return null
    }

    return (
      <>
        <div
          className={css({
            _before: {
              translate: '-50%',
              content: '""',
              pos: 'absolute',
              left: '50%',
              bottom: '0',
              h: '5%',
              bg: 'rgba(255,255,255,0.8)',
              w: '110%',
              transition: '.5s',
              zIndex: '-1',
            },
            _hover: {
              '& .line': {
                color: 'black',
                _before: {
                  bg: 'black',
                },
              },
              _before: {
                h: '100%',
              },
            },
            
            '@media (max-width: 768px)': {
              position: 'absolute !important',
              width: '100%',
              translateY: '-36px',
              top: '50%',
              background: 'rgba(255, 255, 255, 0.6)'
            },
            '@media (min-width: 768px)': {
              h: '360px',
              w: '360px',
              transform: 'scale(1.5)',
              pos: 'absolute',
              rotate: '45deg',
              translate: '-50% -50%',
              top: '50%',
              left: '50%',
              display: 'flex',
              flexDir: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              transition: '.3s',
              lgDown: {
                rotate: '-0deg',
              },
              
            },
          })}
        >
          <span
            className={cx(
              'line',
              css({
                color: 'white',
                letterSpacing: '2px',
                fontSize: '25px',
                textTransform: 'uppercase',
                fontFamily: 'Lato !',
                fontWeight: '300',
                pos: 'relative',
                transition: '.3s',
                _before: {
                  translate: '-50%',
                  content: '""',
                  pos: 'absolute',
                  left: '50%',
                  bottom: '0',
                  h: '5%',
                  bg: 'white',
                  w: '110%',
                  transition: '.3s',
                  zIndex: '-1',
                },
                '@media (max-width: 768px)': {
                  display: 'flex',
                  background: '(255, 255, 255, 0.5)',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#333'
                }
              })
            )}
          >
            {pkg?.label}
          </span>
        </div>

        <ResponsiveImage
          relativePath={`packages/${pkg.id}/${imageName}`}
          wrapperClassName={css({
            position: 'absolute !important',
            transform: 'scale(1.5)',
          })}
          className={css({
            objectFit: 'contain',
            position: 'absolute',
            '& > div': {
              position: 'absolute !important', // S'assure que le div wrapper interne a `position: absolute`
            },
            left: '0',
            top: '0',
            zIndex: '-1',
            width: '100%',
            height: '100%',
            transition: '.3s',
            rotate: '45deg',
            lgDown: {
              rotate: '-0deg',
            },
          })}
        />
      </>
    )
  }

  const getPlaceHolderImage = (name: string) => {
    return (
      <ResponsiveImage
        relativePath={`home/${name}`}
        customStyle={{height: 'auto'}}
        className={css({
          objectFit: 'contain',
          rotate: '45deg',
          pos: 'absolute',
          translate: '-50% -50%',
          left: '50%',
          top: '50%',
          zIndex: '-1',
          minW: 'calc(100% + 60px)',
          width: 'auto !important',
          transition: '.3s',
          lgDown: {
            rotate: '-0deg',
          },
        })}
      />
    )
  }
  const isMobile = useIsMobile();
  const imageData = getPath('home/bg.webp');
  const imageUrl = imageData?.images?.fallback?.src || '';  

  return (
    <div
      className={css({
        pos: 'relative',
        width: '100vw',
        '@media (min-width: 768px)': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          flexGrow: 0,
        },
      })}
      style={!isMobile ? {
        backgroundImage: "url('" + imageUrl + "')",
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      } : {}}
    >
      <div
        className={css({
          '@media (max-width: 768px)': {
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            },
          '@media (min-width: 768px)': {
            display: 'grid',
            marginTop: '-140px',
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
            gridTemplateRows: 'repeat(4, 360px)',
            gap: '10px',
            gridAutoFlow: 'row',
            justifyItems: 'center',
            gridTemplateAreas:
              "'GROSSESSE . .' 'COUPLE BEBE CHILDS1' 'CHILDS2 NOEL NAISSANCE' '. CHILDS3 BAPTEME'",
            zIndex: 99,
            transform: 'rotate(-45deg)',
            transition: '.3s',
            '& .CHILDS1, & .CHILDS2, & .CHILDS3': {
              width: '360px',
            },
            '& > div': {
              pos: 'relative',
              overflow: 'hidden',
              height: '360px',
              '& > div': {
                pos: 'relative',
                overflow: 'hidden',
              },
            },
          },
          
        })}
      >
        <div
          className={`GROSSESSE ${css({ position:'relative', '@media (max-width: 768px)': { width: '100%', aspectRatio: '1', overflow: 'hidden' } })}`}
          onClick={() => handleClickNav('shooting-photo-grossesse')}
        >
          {getPackage('pregnancy')}
        </div>
        <div
          className={`COUPLE ${css({ position:'relative', '@media (max-width: 768px)': { width: '100%', aspectRatio: '1', overflow: 'hidden' } })}`}
          onClick={() => handleClickNav('photographe-couple')}
        >
          {getPackage('couple')}
        </div>
        <div
          className={`BEBE ${css({ position:'relative', '@media (max-width: 768px)': { width: '100%', aspectRatio: '1', overflow: 'hidden' } })}`}
          onClick={() => handleClickNav('photographe-bebe')}
        >
          {getPackage('babys')}
        </div>
        <div
          className={`NOEL ${css({ position:'relative', '@media (max-width: 768px)': { width: '100%', aspectRatio: '1', overflow: 'hidden' } })}`}
          onClick={() => handleClickNav('photographe-de-noel')}
        >
          {getPackage('noel')}
        </div>
        <div
          className={`NAISSANCE ${css({ position:'relative', '@media (max-width: 768px)': { width: '100%', aspectRatio: '1', overflow: 'hidden' } })}`}
          onClick={() => handleClickNav('photographe-pour-naissance')}
        >
          {getPackage('birth')}
        </div>
        <div
          className={`BAPTEME ${css({ position:'relative', '@media (max-width: 768px)': { width: '100%', aspectRatio: '1', overflow: 'hidden' } })}`}
          onClick={() => handleClickNav('photographe-professionnelle')}
        >
          {getPackage('pro')}
        </div>

        {!isMobile && (
          <>
            <div className="CHILDS1">
              <div className="PHOTO1">
                {getPlaceHolderImage('placeholder1.png')}
              </div>
            </div>
            <div className="CHILDS2">
              <div className="PHOTO1">
                {getPlaceHolderImage('placeholder2.png')}
              </div>
              <div className="PHOTO2">
                {getPlaceHolderImage('placeholder3.png')}
              </div>
              <div className="PHOTO3">
                {getPlaceHolderImage('placeholder4.png')}
              </div>
            </div>
            <div className="CHILDS3">
              <div className="PHOTO1">
                {getPlaceHolderImage('placeholder5.png')}
              </div>
              <div className="PHOTO2">
                {getPlaceHolderImage('placeholder6.png')}
              </div>
              <div className="PHOTO3">
                {getPlaceHolderImage('placeholder7.png')}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
