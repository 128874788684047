import React from 'react'
import { HeaderAndFooterLayout } from '../../layouts/HeaderAndFooter'
import { css } from '../../../styled-system/css'
import { HeadFC, navigate } from 'gatsby'
import { Feedbacks_photos } from '../../components/photographie/Feedbacks'
import ImageDisp from '../../components/photographie/ImageDisp'
import ResponsiveImage from '../../components/photographie/ResponsiveImage'
import { Typography } from '../../components/photographie/Typography'
import { Hr } from '../../components/photographie/Hr'
import { Albums } from '../../components/photographie/Albums-home'
import SEO from '../../components/SEO'
import ButtonOrd from '../../components/photographie/ButtonOrd'
import { useIsMobile } from '../../utils/useIsMobile'

function handleClickNav(dir: string) {
  const goTo = '/photographie/' + dir
  navigate(goTo)
}

const PhotosPage = () => {
  
  const isMobile = useIsMobile();
  return (
    <HeaderAndFooterLayout>
      <div
        className={css({
          overflow: 'hidden',
          height: 'auto',
          width: '100%',
          position: 'relative',
          borderBottom: 'solid 8px #F2D98D',
          '@media (min-width: 768px)': {
            maxHeight: '1200px',
            margin: '-20px auto 0',
          },
        })}
      >
        <h1
          className={css({
            color: '#594A2E',
            width: '100%',
            textAlign: 'center',
            fontSize: '30px',
            fontWeight: 'bold',
            '@media (min-width: 768px)': {
              position: 'absolute',
              zIndex: '1',
              top: '10%',
            },
            '@media (max-width: 768px)': {
              margin: '20px 0'
            }
          })}
        >
          Choisissez votre séance photo
        </h1>
        <Albums />
      </div>
      {/*-----------------------------------Photographe-----------------------------------*/}
      <div
        className={css({
          position: 'relative',
          maxWidth: '1300px',
          width: '100%',
          margin: 'auto',
          marginTop: '100px',
          textAlign: 'center',
        })}
      >
        <h2
          className={css({
            fontSize: '30px',
            marginBottom: '100px',
            marginTop: '50px',
            lgDown: {
              marginTop: '0.5rem',
              marginBottom: '1.5rem',
            },
          })}
        >
          Votre photographe spécialiste
        </h2>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'row',
            margin: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            width: '90%',
            maxWidth: '1200px',
            '@media (max-width: 768px)': {
              flexDir: 'column',
              marginBottom: '6rem',
            },
          })}
        >
          {/*------------------------ */}
          <ResponsiveImage
            className={css({
              // width: '60%',
              padding: '0 2rem',
              objectFit: 'contain',
              borderRadius: '4vh ',
              flexGrow: '0',
              flexShrink: '1',
              '@media (max-width: 768px)': {
                width: '100%',
                aspectRatio: '1.7',
                margin: 'auto',
              },
            })}
            imageName="inna"
          ></ResponsiveImage>
          {/*------------------------ */}
          <div
            className={css({
              display: 'flex',
              paddingLeft: '20px',
              flexDirection: 'column',
              gap: '10px',
              width: '60%',
              flexGrow: 1,
              textAlign: 'left',
              '@media (max-width: 768px)': { width: '90%', marginTop: '2rem' },
            })}
          >
            <p>
              Je suis Inna,
              <strong>
                photographe spécialisée dans la maternité et la petite enfance,
                basée à Chéreng, près de Lille.
              </strong>
            </p>
            <p>
              Mère de deux garçons, je suis inspirée par la beauté de la vie
              éphémère. Je vous accompagne tout au long de votre vie pour créer
              ensemble votre patrimoine photographique.
            </p>
            <p>
              
              Votre couple, votre mariage, votre grossesse, la naissance de vos
              enfants, les baptêmes sont autant de moments importants de vos
              vies pour lesquels je serais honorée que vous m&apos;accordiez
              votre confiance.
            </p>
            <p>
              Voir ensuite votre famille grandir et vous retrouver lors de
              séances familiales est un réel bonheur.
            </p>
            <p>
              Je serais ravie de vous accompagner dans la création de précieux
              souvenirs familiaux.
            </p>
            <p>
              Vous êtes photographe et souhaitez améliorer vos séances photo ?
              Visitez notre boutique d’
              <a
                className={css({
                  color: '#594A2E',
                  textDecoration: 'underline',
                })}
                href="https://www.accessoire-photo.fr/"
              >
                accessoires photo.
              </a>
            </p>
          </div>
        </div>
        {process.env.GATSBY_NO_ORDER === 'true' ? null : (
          <div
            className={css({
              marginTop: '5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            })}
          >
              <ButtonOrd  />
          </div>
        )}
      </div>
      {/*----------------------------------Evenement------------------------------------*/}
      <div
        className={css({
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '1300px',
          width: '90%',
          margin: '0 auto 50px',
          marginTop: '50px',
          justifyContent: 'center',
          gap: '50px',
          textAlign: 'center',
        })}
      >
        <h2
          className={css({
            fontSize: '30px',
            marginBottom: '50px',
            marginTop: '50px',
          })}
        >
          Nos séances photos événements
        </h2>

        {/*-----------------------flex 1--------------------------*/}
        <div
          className={css({
            display: 'flex',
            flexDirection: 'row',
            gap: '50px',
            justifyContent: 'center',
            '@media (max-width: 768px)': {
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 0,
            },
          })}
        >
          <div
            className={css({
              position: 'relative',
              borderRadius: '10px',
              width: '485px',
              height: '485px',
              cursor: 'pointer',
              '@media (max-width: 768px)': { 
                width: '100%', 
                aspectRatio: '1',
                height: 'auto', 
              },
            })}
            onClick={() => handleClickNav('photographe-de-noel')}
          >
            <h3
              className={css({
                fontSize: '40px',
                color: 'white',
                width: '100%',
                textAlign: 'center',
                position: 'absolute',
                zIndex: '5',
                top: '40%',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              })}
            >
              NOEL
            </h3>
            <ResponsiveImage
              className={css({
                borderRadius: '15px',
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              })}
              imageName="photographe-noel-lille@digituniverse_hp"
            />
            <div
              className={css({
                width: '50%',
                height: '50%',
                backgroundColor: '#F2D98D',
                position: 'absolute',
                borderRadius: '20px',
                left: '-2%',
                top: '-2%',
                zIndex: '-1',
                '@media (max-width: 768px)': { display: 'none' },
              })}
            ></div>
          </div>

          <div
            className={css({
              position: 'relative',
              borderRadius: '10px',
              width: '485px',
              height: '485px',
              cursor: 'pointer',
              '@media (max-width: 768px)': { 
                width: '100%', 
                aspectRatio: '1', 
                height: 'auto' },
            })}
            onClick={() => handleClickNav('photographe-bapteme')}
          >
            <h3
              className={css({
                fontSize: '40px',
                color: 'white',
                width: '100%',
                textAlign: 'center',
                position: 'absolute',
                zIndex: '5',
                top: '40%',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              })}
            >
              BAPTEME
            </h3>
            <ResponsiveImage
              className={css({
                borderRadius: '15px',
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              })}
              imageName="photographe-bapteme-lille@digituniverse_hp"
            />
            <div
              className={css({
                width: '50%',
                height: '50%',
                backgroundColor: '#F2D98D',
                position: 'absolute',
                borderRadius: '20px',
                left: '52%',
                top: '-2%',
                zIndex: '-1',
                '@media (max-width: 768px)': { display: 'none' },
              })}
            ></div>
          </div>
        </div>

        {/*-----------------------flex 2--------------------------*/}
        <div
          className={css({
            display: 'flex',
            flexDirection: 'row',
            gap: '50px',
            justifyContent: 'center',
            '@media (max-width: 768px)': {
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 0,
              height: 'auto',
            },
          })}
        >
          <div
            className={css({
              position: 'relative',
              borderRadius: '10px',
              width: '485px',
              height: '485px',
              cursor: 'pointer',
              '@media (max-width: 768px)': { width: '100%', aspectRatio: '1' },
            })}
            onClick={() => handleClickNav('shooting-photo-paques')}
          >
            <h3
              className={css({
                fontSize: '40px',
                color: 'white',
                width: '100%',
                textAlign: 'center',
                position: 'absolute',
                zIndex: '5',
                top: '40%',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              })}
            >
              PAQUES
            </h3>
            <ResponsiveImage
              className={css({
                borderRadius: '15px',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              })}
              imageName="photographe-paques-lille@digituniverse_hp"
            />
            <div
              className={css({
                width: '50%',
                height: '50%',
                backgroundColor: '#F2D98D',
                position: 'absolute',
                borderRadius: '20px',
                left: '-2%',
                top: '52%',
                zIndex: '-1',
                '@media (max-width: 768px)': { display: 'none' },
              })}
            ></div>
          </div>

          <div
            className={css({
              position: 'relative',
              borderRadius: '10px',
              width: '485px',
              height: '485px',
              cursor: 'pointer',
              '@media (max-width: 768px)': { width: '100%', aspectRatio: '1' },
            })}
            onClick={() => handleClickNav('photographe-halloween')}
          >
            <h3
              className={css({
                fontSize: '40px',
                color: 'white',
                width: '100%',
                textAlign: 'center',
                position: 'absolute',
                zIndex: '5',
                top: '40%',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              })}
            >
              HALLOWEEN
            </h3>
            <ResponsiveImage
              className={css({
                borderRadius: '15px',
                height: '485px',
                objectFit: 'cover',
              })}
              imageName="photographe-halloween-lille@digituniverse_hp"
            />
            <div
              className={css({
                width: '50%',
                height: '50%',
                backgroundColor: '#F2D98D',
                position: 'absolute',
                borderRadius: '20px',
                left: '52%',
                top: '52%',
                zIndex: '-1',
                '@media (max-width: 768px)': { display: 'none' },
              })}
            ></div>
          </div>
        </div>
      </div>

      <ImageDisp
        images={[
          'photographe-couple-lille@digituniverse_0001',
          'photographe-naissance-lille@digituniverse_hp',
          'photographe-grossesse-lille@digituniverse_hp',
        ]}
      ></ImageDisp>
      <div
        className={css({
          marginTop: '5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        })}
      >
          <ButtonOrd  />
      </div>
      <ImageDisp
        images={[
          'photographe-halloween-lille@digituniverse_hp',
          'photographe-bebe-lille@digituniverse_hp',
          'photographe-noel-lille@digituniverse_hp',
          ]}
      ></ImageDisp>
      {/*---------------------------------- Avis ------------------------------------*/}
      <div
        className={css({
          width: '90%',
          margin: '0 auto 0',
        })}
      >
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1.875rem',
            padding: '1.25rem',
            width: '100%',
          })}
        >
          <Hr />
          <Typography
            className={css({
              color: '#19213D',
              fontWeight: '500',
              textAlign: 'center',
              fontSize: '2rem',
              '@media (max-width: 768px)': {
                fontSize: '1.5rem',
              },
            })}
            as="h2"
          >
            Ils ont aimé leur séance photo avec Digit Universe
          </Typography>

          <Feedbacks_photos title={''} />
        </div>
      </div>
    </HeaderAndFooterLayout>
  )
}

export default PhotosPage

export const Head: HeadFC = () => (
  <SEO
    title="Digit Universe Photographie à Lille - séances photo professionnelles"
    description="Découvrez Digit Universe Photographie, photographe à Lille : shooting photo grossesse, photographe bébé, couple, naissance, baptême, mariage. Capturez vos moments uniques avec notre photographe professionnel."
    robotsContent="index, follow"
  />
)
